import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Settings from "../components/Settings"
import useLocalStorage from "../components/useLocalStorage"

function SettingsPage() {

    const [essentials] = useLocalStorage("essentials")
    const navigate = useNavigate()

    useEffect(() => {
        
        if (essentials === undefined) {
            console.log("User is NOT logged in, redirecting back to login page")
            navigate('/login', { replace: true })
            return
            
        } else if (essentials.userID === undefined) {
            console.log("User is NOT logged in, redirecting back to login page")
            navigate('/login', { replace: true })
            return
        }
        
    },[])
    
    return <div>
        <Settings/>

    </div>
}

export default SettingsPage