import style from "../css/Loader.module.css"

function Loader() {

    return <div className={style.ldsFacebook}>
            <div></div>
            <div></div>
            <div></div>
        </div> 

}

export default Loader