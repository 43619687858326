import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import style from "../css/UserProfile.module.css";
import { config } from "../config";

function UserProfile(props) {
	const [profile, setProfile] = useState();

	function handleExitClick() {
		setProfile();
		props.click();
	}

	async function getUserProfile(userID) {
		await fetch(`${config.API_URL}/users/${userID}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then(async (response) => {
			let jsonResponse = await response.json();
			console.log("#GETUSERPROFILE", jsonResponse);
			setProfile(
				<>
					<div className={style.profileContainer}>
						<div className={style.exitButton} onClick={handleExitClick}>
							✖
						</div>
						<div
							className={style.profileImg}
							style={{
								backgroundImage: "url(" + jsonResponse.profilePic + ")",
							}}
						></div>
						<h1
							className={style.username}
							style={{ color: jsonResponse.colorCode }}
						>
							{jsonResponse.username}
						</h1>

						<div className={style.infoParent}>
							<p className={style.status}>
								{jsonResponse.status ? jsonResponse.status : ""}
							</p>
							{/* 
							<div className={style.plugContainer}>
								<a className={style.plugLink}>https://twitter.com/cutlett_</a>
								<svg className={style.linkIcon} viewBox="0 0 512 512">
									<path
										d="M200.66 352H144a96 96 0 010-192h55.41M312.59 160H368a96 96 0 010 192h-56.66M169.07 256h175.86"
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="48"
									/>
								</svg>
							</div> */}

							<div className={style.statsContainer}>
								<h3 className={style.upVotes}>{jsonResponse.upVotes}</h3>
								<svg
									className={style.upVotesIcon}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
								</svg>

								<h3 className={style.downVotes}>{jsonResponse.downVotes}</h3>
								<svg
									className={style.downVotesIcon}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
								</svg>

								<h3 className={style.hearts}>{jsonResponse.hearts}</h3>
								<svg className={style.heartsIcon} viewBox="0 0 512 512">
									<path d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
								</svg>
							</div>

							<div className={style.joinContainer}>
								<div className={style.join}>Joined on:</div>
								<p className={style.joinDate}>
									{new Date(jsonResponse.joinDate).toDateString()}
								</p>
							</div>
						</div>
					</div>

					<div
						className={style.profileBackground}
						onClick={handleExitClick}
					></div>
				</>
			);
		});
	}

	useEffect(() => {
		if (props.userID === "") {
			console.log("Profile click ready....");
			return;
		}

		getUserProfile(props.userID);

		console.log("Fetching user profile");
	}, []);

	return ReactDOM.createPortal(
		profile ? profile : "",
		document.getElementById("portal")
	);
}

export default UserProfile;
