import { config } from "../../config";

export async function reorderQueueTrack(uri, start, insert, userID) {
	await fetch(`${config.API_URL}/spotify/queue/${userID}`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			uris: [uri],
			range_start: start,
			insert_before: insert,
		}),
	}).then(async (response) => {
		let a = await response.json();
		return a;
	});
}
