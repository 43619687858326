import { useEffect, useState, useRef } from "react";
import useLocalStorage from "./useLocalStorage";
import style from "../css/Deck.module.css";
import Loader from "./Loader";
import PreviewButton from "./PreviewButton";
import { getQueuePlaylist } from "../api/spotify/getQueuePlaylist.js";
import { deleteQueueTrack } from "../api/spotify/deleteQueueTrack";
import { reorderQueueTrack } from "../api/spotify/reorderQueueTrack";
console.log("Deck.js loaded");

let buttonReset;
let dragging = false;

function Deck(props) {
	console.log("Deck.js rendering");

	const [essentials, setEssentials] = useLocalStorage("essentials");
	const [decklist, setDeckList] = useState();
	const dragItem = useRef();
	const dragOverItem = useRef();

	function previewButtonReset(resetFunc) {
		if (buttonReset) {
			console.log("resetting previous button");
			buttonReset();
			buttonReset = resetFunc;
			return;
		}

		console.log("setting initial button");
		buttonReset = resetFunc;
		console.log(resetFunc);
	}

	//Remove track from users spotify queue playlist
	async function removeTrack(uri) {
		let array = [...decklist];
		console.log("Removing", uri);
		let newArr = array.filter((i) => {
			if (i.track) {
				if (i.track.uri !== uri) {
					return i;
				} else {
					return;
				}
			} else {
				if (i.uri !== uri) {
					return i;
				} else {
					return;
				}
			}
		});
		props.deckCount(newArr.length);
		setDeckList(newArr);

		//Contact backend & spotify for removal of track
		await deleteQueueTrack(uri, essentials.userID);
	}

	const handleDragStart = (e, position) => {
		dragging = true;
		console.log("user has started dragging");
		dragItem.current = position;
	};

	const handleDragEnd = async (e, uri, name) => {
		dragging = false;
		console.log("user has stopped dragging");
		if (dragItem.current === dragOverItem.current) {
			dragItem.current = null;
			dragOverItem.current = null;
			return;
		}
		let rangeStart;
		let insertBefore;
		if (dragItem.current < dragOverItem.current) {
			rangeStart = dragItem.current;
			insertBefore = dragOverItem.current + 1;
		} else {
			rangeStart = dragItem.current;
			insertBefore = dragOverItem.current;
		}
		const copyListItems = [...decklist];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		setDeckList(copyListItems);

		//Pass changes in queue to spotify queue playlist
		await reorderQueueTrack(uri, rangeStart, insertBefore, essentials.userID);
		dragItem.current = null;
		dragOverItem.current = null;
	};

	const handleDragOver = (e, position) => {
		dragOverItem.current = position;
	};

	//useEffect ran whenever a new track is added to the deck from search results, reducing unnecessary spotify fetch playlist call
	useEffect(() => {
		if (decklist === undefined) {
			return;
		}

		console.log("Deckupdate Length:", props.deckUpdate.length);

		if (props.deckUpdate === "clear") {
			setDeckList([]);
			return;
		}

		if (props.deckUpdate.length > 1) {
			let a = [...props.deckUpdate, ...decklist];
			props.deckCount(a.length);
			setDeckList(a);
			return;
		}

		let a = [props.deckUpdate, ...decklist];
		props.deckCount(a.length);
		setDeckList(a);
		return;
	}, [props.deckUpdate]);

	//useffect to be ran when queue is first loaded, fetching queue playlist
	useEffect(() => {
		//fetch users queue playlist from spotify
		(async () => {
			console.log("fetching queue");
			//Contact spotify for user's queue playlist
			let queue = await getQueuePlaylist(essentials.userID);
			console.log("Spotify queue fetch results", queue.tracks.items);
			props.deckCount(queue.tracks.items.length);
			setDeckList(queue.tracks.items);
		})();
	}, []);

	return (
		<ul className={style.container}>
			{decklist ? (
				decklist.map((i, index) => {
					let a;
					//Items coming from spotify
					if (i.track) {
						a = {
							uri: i.track.uri,
							artists: i.track.artists[0].name,
							name: i.track.name,
							image:
								i.track.album.images.length > 0
									? i.track.album.images[2].url
									: "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg",
						};
						//Items from searchResults
					} else {
						a = i;
					}

					return (
						<>
							<li
								key={a.uri + "-" + Date.now()}
								className={style.lineItem}
								draggable={true}
								onDragStart={(e) => {
									handleDragStart(e, index);
								}}
								onDragEnter={(e) => handleDragOver(e, index)}
								onDragEnd={(e) => {
									handleDragEnd(e, a.uri, a.name);
								}}
								onContextMenu={(event) => {
									event.preventDefault();
									alert(a.uri);
								}}
							>
								<div className={style.detailContainer}>
									<button
										className={style.removeButton}
										onClick={() => removeTrack(a.uri)}
									>
										-
									</button>
									<img className={style.albumArt} src={a.image} />
									<div>
										<div>{a.name}</div>
										<div className={style.artistName}>{a.artists}</div>
									</div>
								</div>
								<PreviewButton uri={a.uri} reset={previewButtonReset} />
							</li>
						</>
					);
				})
			) : (
				<Loader />
			)}
		</ul>
	);
}

export default Deck;
