import {config} from "../../config"

export async function previewTrack(uri, user) {
    console.log("#PreviewButton is attempting to preview track:", uri);
    return await fetch(`${config.API_URL}/spotify/play`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            userID: user.userID,
            uri: uri,
            pos: 30000,
        }),
    }).then(async (response) => {
        if (response.status === 404) {
            console.log("404 detected, no player found... reload player here.");
            return new Error("404 detected, no player found... reload player here.")
        }

        let jsonResponse = await response.json();
        console.log(jsonResponse);
        return jsonResponse
    });
}
