import { useContext, useEffect, useState } from "react";
import { GlobalSocket } from "../App";
import style from "../css/SongMeter.module.css";

function SongMeter(props) {
	
	const socket = useContext(GlobalSocket)
	const [meterPos, setMeterPos] = useState(50)
	const [vote, setVote] = useState()

	function handleTrackVote(value) {
		if (value === vote) {
			setVote()
		} else {
			setVote(value)
		}
		socket.emit("meter-vote", value);
	}

	useEffect(() => {

		let up = 0
		let down = 0

		props.userList.forEach(a => {
			if (a.vote !== null) {
				if (a.vote === true) {
					up++
					return
				} else {
					down++
					return
				}
			} else {
				setVote()
				return
			}
		});

		let upvotes = (up / props.userList.length) * 50
		let downvotes = (down / props.userList.length) * 50

		setMeterPos(50 - upvotes + downvotes)

	}, [props.userList]);

	return (
		<div className={style.meterContainer}>
			<svg
				className={vote === undefined ? style.thumbsUp : (vote ? style.thumbsUp  : style.thumbUpCancel)}
				onClick={() => handleTrackVote(true)}
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
			</svg>

			<div className={style.meter}>
				<svg className={style.marker} style={{left: "calc(" + meterPos + "% - 10px)"}} viewBox="0 0 512 512">
					<path d="M414 321.94L274.22 158.82a24 24 0 00-36.44 0L98 321.94c-13.34 15.57-2.28 39.62 18.22 39.62h279.6c20.5 0 31.56-24.05 18.18-39.62z" />
				</svg>
			</div>

			<svg
				className={vote === undefined ? style.thumbsDown : (vote ? style.thumbDownCancel : style.thumbsDown)}
				onClick={() => handleTrackVote(false)}
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
			</svg>
		</div>
	);
}

export default SongMeter;
