import { config } from "../../config";

export async function refreshUserToken(userID, callback) {
    await fetch(`${config.API_URL}/spotify/refresh/${userID}`, {
        method: "GET",
        headers: {
			"Content-Type": "application/json",
		}
    })
    .then((response) => {
        let a = response.json()
        callback(a)
    })
}