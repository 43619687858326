import {config} from "../../config"

export async function pauseTrack(user) {
    console.log("#pauseTrack is attempting to pause the player");
    await fetch(
        `${config.API_URL}/spotify/pause/${user.userID}`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then(async (response) => {
        if (response.status === 404) {
            console.log("404 detected, no player found... reload player here.");
        }

        let jsonResponse = await response.json();
        console.log(jsonResponse);
        
    });
}