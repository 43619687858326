import { useState } from "react"
import { Outlet } from "react-router-dom"
import Chat from "./Chat"
import Sidebar from "./Sidebar"
import Topbar from "./Topbar"
import style from "../css/RoomLayout.module.css"
console.log("Room layout loaded...")


function RoomLayout() {
    console.log("Room layout Ran...")
    return(
    <div className={style.app}>
        <div className={style.topbarContainer}>
           <Topbar title="SOCIALMUSIC" logoLink="https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/991px-Spotify_icon.svg.png"/> 
        </div>
        <div className={style.sidebarContainer}>
           <Sidebar/> 
        </div>
        <div className={style.chatContainer}>
           <Chat /> 
        </div>
               
         <Outlet/>
    </div>
    )
}

export default RoomLayout