import { config } from "../../config";

export async function deleteQueueTrack(uri, userID) {
	await fetch(`${config.API_URL}/spotify/queue/${userID}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			uri: uri,
		}),
	}).then(async (response) => {
		let a = await response.json();
		return a;
	});
}
