import Sidebar from "../components/Sidebar"
import Topbar from "../components/Topbar"
import Queue from "../components/Queue"
import {useEffect, useState} from "react"
import useLocalStorage from "../components/useLocalStorage"
import { useNavigate } from "react-router-dom"

function QueuePage() {

    const [ isQueue ] = useState(true)
    const [ essentials, setEssentials ] = useLocalStorage("essentials")
    const navigate = useNavigate()

    useEffect(() => {
        
        if (essentials === undefined) {

            console.log("User is NOT logged in, redirecting back to login page")
            navigate('/login', { replace: true })
            return
            
        } else if (essentials.userID === undefined) {
            console.log("User is NOT logged in, redirecting back to login page")
            navigate('/login', { replace: true })
            return
        }



    }, [])

    return <div>
        <Queue/>
    </div>
}

export default QueuePage