const dev = {
    API_URL: "https://socialmusic.agp2p.com",
    REDIRECT_URL: "http://localhost:3000/loading"
}

const prod = {
    API_URL: "https://socialmusic.agp2p.com",
    REDIRECT_URL: "https://socialmusic.agp2p.com/loading"
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;