import { useContext, useEffect, useState } from "react";
import { GlobalSocket } from "../App";
import Loader from "./Loader";
import style from "../css/TrackHistory.module.css";
import PreviewButton from "./PreviewButton";

let buttonReset

function TrackHistory() {
	let [tracks, setTracks] = useState();
	let socket = useContext(GlobalSocket);

	useEffect(() => {
		console.log("Loading history tracks...");
		socket.emit("updateHistory");

		socket.on("updateHistory", (trackHistory) => {
			console.log("History:", trackHistory);
			setTracks(trackHistory);
		});

		return () => {
			socket.off("updateHistory");
		};
	}, []);

	function previewButtonReset(resetFunc) {
		if (buttonReset) {
			console.log("resetting previous button")
			buttonReset()
			buttonReset = resetFunc
			return
		}

		console.log("setting initial button")
		buttonReset = resetFunc
		console.log(resetFunc)
	}


	return (
		<ul className={style.trackList}>
			<li className={style.trackListTitle}>TRACK HISTORY</li>
			{tracks ? (
				tracks.map((a) => {
					return (
						<li className={style.trackItem} key={a.track.id}>
							<img src={a.track.album.images[2].url}></img>
							<div className={style.trackInfo}>
								<div className={style.trackName}>{a.track.name}</div>
								<div className={style.trackArtist}>{a.track.artists[0].name}</div>
							</div>
							<PreviewButton uri={a.track.uri} reset={previewButtonReset}/>
						</li>
					);
				})
			) : (
				<Loader />
			)}
		</ul>
	);
}

export default TrackHistory;
