import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalSocket } from "../App";
import style from "../css/Sidebar.module.css";
import OnlineList from "./OnlineList";
import useLocalStorage from "./useLocalStorage";
import SpotifyPlayer from "./SpotifyPlayer";
import TrackHistory from "../components/TrackHistory";
import SongMeter from "../components/SongMeter";
console.log("Sidebar component loaded....");

function Sidebar() {
	const navigate = useNavigate();
	const [list, setList] = useLocalStorage("onlineList", []);
	const socket = useContext(GlobalSocket);
	const [isQueue, setQueueBtn] = useState(false);
	const [isHistory, setHistoryBtn] = useState(false);
	const [sideModule, setSideModule] = useState(false);
	const [isMobile, setMobile] = useState(false)

	function handleQueueClick() {
		if (isQueue === true) {
			setQueueBtn(false);
			navigate("/room");
		} else {
			setQueueBtn(true);
			navigate("/room/queue");
		}
	}

	function handleHistoryClick() {
		setSideModule(!sideModule);
		setHistoryBtn(!isHistory);
	}

	useEffect(() => {
		if (window.location.pathname === "/room/queue") {
			setQueueBtn(true);
		}

		socket.on("online2", (array) => {
			console.log("#online", array);
			setList(array);
		});

		return () => {
			socket.off("online2");
		};
	}, []);

	useEffect(() => {
		console.log("width", window.innerWidth)
		if (window.innerWidth < 800) {
			setMobile(true)
		} else {
			setMobile(false)
		}
	}, [window.innerWidth])

	return (
		<div className={style.sidebarContainer}>
			<div className={style.playerContainer}>
				<SpotifyPlayer />
			</div>

			<div className={style.middleContainer}>
				{sideModule ? <TrackHistory /> : <OnlineList userArray={list} />}
			</div>

			<div className={style.meterContainer}>
				<SongMeter userList={list}/>
			</div>

			<div className={style.bottomContainer}>
				<button className={style.queueButton} onClick={handleQueueClick}>
					{isQueue ? "ROOM" : "DECK"}
				</button>
				<button className={style.historyButton} onClick={handleHistoryClick}>
					{isHistory ? "ONLINE" : "HISTORY"}
				</button>
				{isMobile ? <button className={style.onlineButton} onClick={() => {alert(window.innerWidth)}}>
					MOBILE
				</button> : ""}
			</div>
		</div>
	);
}

export default Sidebar;
