import { config } from "../config"

function Login(props) {
	function passClick() {
		window.location.replace(
			`https://accounts.spotify.com/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID}&scope=${scope}&redirect_uri=${config.REDIRECT_URL}`
		);
	}

	return (
		<div className="login-window">
			<h1>{props.title}</h1>
			<h2>LOGIN USING SPOTIFY</h2>
			<button className="spotify-login-btn" onClick={passClick}>
				SPOTIFY LOGIN
			</button>
		</div>
	);
}

export default Login;

let scope =
	"user-modify-playback-state user-read-playback-state user-read-currently-playing user-read-recently-played user-read-playback-position user-top-read playlist-read-private playlist-modify-private playlist-read-collaborative app-remote-control streaming user-read-email user-read-private user-library-modify user-library-read playlist-modify-public";

console.log("Login page loaded...");
