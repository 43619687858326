import { useState } from "react";
import style from "../css/OnlineList.module.css";
import UserProfile from "./UserProfile";

function OnlineList(props) {
	const [profileClick, setProfileClick] = useState();

	function handleUserClick(id) {
		if (id) {
			setProfileClick(id);
		} else {
			setProfileClick();
		}
	}

	let userList = props.userArray.map((user, index) => {
		let profilepic = {
			backgroundImage: "url( " + user.profilePic + ")",
		};
		return (
			<li
				key={index}
				className={style.userContainer}
				onClick={() => {
					handleUserClick(user.userID);
				}}
			>
				<div className={style.image} style={profilepic}></div>
				{user.vote !== null ? (
					user.vote == true ? (
						<svg
							className={style.thumbsUp}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
						</svg>
					) : (
						<svg
							className={style.thumbsDown}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
						</svg>
					)
				) : (
					""
				)}
				<div className={style.username} style={{ color: user.colorCode }}>
					{user.username}
				</div>
			</li>
		);
	});

	return (
		<div>
			<ul className={style.nameList}>{userList}</ul>
			{profileClick ? (
				<UserProfile userID={profileClick} click={handleUserClick} />
			) : (
				""
			)}
		</div>
	);
}

export default OnlineList;
