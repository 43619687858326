import { useContext, useEffect, useState } from "react"
import style from "../css/UserQueue.module.css"
import useLocalStorage from "./useLocalStorage"
import DjQueueList from "./DjQueueList"
import { GlobalSocket } from "../App"


function UserQueue() {

    const [ djArray, setDjArray ] = useState([])
    const [ essentials ] = useLocalStorage("essentials")
    const socket = useContext(GlobalSocket)

    function handleJoin() {
        console.log("Joining DJ queue")
        socket.emit("joinDJ", essentials)
    }

    function handleSkip() {
        console.log("Requesting track skip...")
        socket.emit("skipTrack")
    }

    useEffect(() => {
        socket.on("joinDJ2", (array) => {
            console.log("DJ QUEUE:", array)
            setDjArray(array)
        })

        return () => {socket.off("joinDJ2")}
    }, [])

    return <div className={style.djContainer}>
        <div className={style.turntable}></div>
        <span className={style.dot}></span>
        <span className={style.pin}></span>
        {djArray.length > 0 ? <button onClick={handleSkip} className={style.skipButton}>SKIP</button> : ""}
        <button className={djArray.some((a) => a.userID === essentials.userID) ? style.joinButton2 : style.joinButton} onClick={handleJoin}> {djArray.some((a) => a.userID === essentials.userID) ? "STEP DOWN" : "JOIN"} </button>
        <ul className={style.djList}>
            <div className={style.listStart}></div>
            <DjQueueList array={djArray}/>
        </ul>
    </div>

}

export default UserQueue