// Contact backened to check if the user is already registered and return ID, if not create user and return ID

import { config } from "../../config";

export async function loginUser(authToken) {
	console.log("#authorizeUserInit:Loading Fetching user Spotify Profile Data");
	console.log(`Fetching: ${config.API_URL}/auth/create/${authToken}`)
	console.log(`Token: ${authToken}`)
	let a = await fetch(`${config.API_URL}/auth/create/${authToken}`, {
		method: "GET",
	})
	.then(async (response) => {
		console.log("#authorizeUserInit Good", response.status);
		if (response.status > 299) {
			console.log(response.statusText);
			console.log(response);
			return "error";
		}
		return await response.json();
	})
	.then((response) => {
		console.log("#authorizeUserInit response:", response);
		return response;
	});

	return a;
}
