import { useState, useEffect } from "react";
import { pauseTrack } from "../api/spotify/pauseTrack";
import { previewTrack } from "../api/spotify/previewTrack";
import style from "../css/PreviewButton.module.css";
import useLocalStorage from "./useLocalStorage";

let timer;

function PreviewButton(props) {


	const [isPlaying, setPlaying] = useState(false);
	const [essentials] = useLocalStorage("essentials");

	useEffect(() => {
		return () => {
			resetButton()
		}
	}, [])


	function resetButton() {
		setPlaying(false)
		clearTimeout(timer)
	}

	async function handlePreviewClick(uri, user) {
		console.log(
			"Starting preview"
		)
		await previewTrack(uri, user)
		setPlaying(true);
        props.reset(resetButton)
        timer = setTimeout(async () => {
            await pauseTrack(user)
            setPlaying(false);
        }, 30000);
	}



	return (
		<div className={style.previewContainer}>
			{isPlaying ? (
				<svg
					onClick={async () => {
						await pauseTrack(essentials)
						setPlaying(false);
					}}
					className={style.PreviewButton}
					viewBox="0 0 512 512"
				>
					<path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm-32 272a16 16 0 01-32 0V192a16 16 0 0132 0zm96 0a16 16 0 01-32 0V192a16 16 0 0132 0z" />
				</svg>
			) : (
				<svg
					onClick={() => handlePreviewClick(props.uri, essentials)}
					className={style.PreviewButton}
					viewBox="0 0 512 512"
				>
					<path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm74.77 217.3l-114.45 69.14a10.78 10.78 0 01-16.32-9.31V186.87a10.78 10.78 0 0116.32-9.31l114.45 69.14a10.89 10.89 0 010 18.6z" />
				</svg>
			)}
		</div>
	);
}

export default PreviewButton;
