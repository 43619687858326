import { config } from "../../config";

export async function getUserProfile(userId) {
	console.log("#getUserProfile= Fetching user's existing profile");
	let a = await fetch(`${config.API_URL}/users/${userId}`, {
		method: "GET",
	})
		.then((response) => response.json())
		.then(async (response) => {
			console.log("#getUserProfile:", response);
			return response;
		});
	console.log("#getUserProfile= Fetching complete...");
	return a;
}
