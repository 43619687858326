import { config } from "../../config";

export async function getQueuePlaylist(userID) {
	return await fetch(`${config.API_URL}/spotify/queue/${userID}`, {
		method: "GET",
	}).then((response) => {
		if (response.status > 299) {
			console.log("Deck fetch error occured...");
			return false;
		} else {
			console.log("Deck fetch complete");
			return response.json();
		}
	});
}
