import style from "../css/DjQueueList.module.css"

function DjQueueList (props) {
    // console.log(props.array)

    let DJLIST = props.array.map((item, index) => {

        let profilepic = {
            backgroundImage: "url( " + item.profilePic + ")"
        }

        return <li key={index} className={style.profile}>
            <div className={style.pfp} style={profilepic}>
            </div>
        </li>
    })

    return DJLIST
}

export default DjQueueList