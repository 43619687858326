import { useContext, useRef, useState } from "react";
import { config } from "../config";
import { useNavigate } from "react-router-dom";
import { GlobalSocket } from "../App";
import style from "../css/Settings.module.css";
import useLocalStorage from "./useLocalStorage";

function Settings() {
	let navigate = useNavigate();
	const socket = useContext(GlobalSocket);
	const [essentials, setEssentials] = useLocalStorage("essentials");

	let usernameRef = useRef();
	let userColorRef = useRef();
	let profileURLRef = useRef();
	let profileStatusRef = useRef();

	const [getSuccess, setSuccess] = useState("");
	const [profileError, setProfileError] = useState("");
	const [getUsername, setUsername] = useState("");
	const [getColor, setColor] = useState("");
	const [getStatus, setStatus] = useState("");

	function resetMessages() {
		setSuccess("");
		setProfileError("");
		setUsername("");
		setColor("");
	}

	async function changeUsername(event) {
		event.preventDefault();
		resetMessages();
		console.log(usernameRef.current.value);
		// let userID = localStorage.getItem("userID")
		let a = await fetch(`${config.API_URL}/users/${essentials.userID}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: usernameRef.current.value,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				return response;
			});
		console.log("New username set to:", a.data.username);
		setEssentials({ ...essentials, username: a.data.username });
		socket.emit("patchAttribute", { username: usernameRef.current.value });
		usernameRef.current.value = "";
		setUsername("New username set to: " + a.data.username);
	}

	async function changeColor(event) {
		event.preventDefault();
		resetMessages();
		console.log(userColorRef.current.value);
		let a = await fetch(
			`${config.API_URL}/users/${essentials.userID}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					colorCode: userColorRef.current.value,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				return response;
			});
		console.log("New color code set to:", a.data.colorCode);
		setEssentials({ ...essentials, colorCode: a.data.colorCode });
		socket.emit("patchAttribute", { colorCode: userColorRef.current.value });
		setColor("New chat username color set to: " + a.data.colorCode);
	}

	async function setProfilePic(event) {
		event.preventDefault();
		resetMessages();
		console.log(profileURLRef.current.value);
		let a = await fetch(
			`${config.API_URL}/users/${essentials.userID}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					profilePic: profileURLRef.current.value,
				}),
			}
		).then(async (response) => {
			console.log(response.status);
			if (response.status === 400) {
				let a = await response.json();
				return a.message;
			} else {
				let a = await response.json();
				profileURLRef.current.value = "";
				return a;
			}
		});

		console.log(a);

		if (a.message === "User updated") {
			console.log("New profilePic set to:", a.data.profilePic);
			setEssentials({ ...essentials, profilePic: a.data.profilePic });
			socket.emit("patchAttribute", { profilePic: a.data.profilePic });
			setSuccess("Updated...");
			return;
		} else {
			setProfileError(a);
			profileURLRef.current.value = "";
		}
	}

	async function changeStatus(event) {
		event.preventDefault();
		resetMessages();
		console.log(profileStatusRef.current.value);
		let a = await fetch(
			`${config.API_URL}/users/${essentials.userID}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					status: profileStatusRef.current.value,
				}),
			}
		)
			.then((response) => response.json())
			.then((response) => {
				return response;
			});
		console.log("Status set to:", a.data.status);
		usernameRef.current.value = "";
	}

	return (
		<div className={style.settingsContainer} onClick={resetMessages}>
			<div className={style.formContent}>
				<h1 className={style.settingsTitle}>PROFILE SETTINGS</h1>

				<form
					className={style.usernameSettingContainer}
					onSubmit={changeUsername}
				>
					<label htmlFor="usernameInput">USERNAME:</label>
					<h2 className={style.success}>{!getUsername ? "" : getUsername}</h2>
					<input
						className={style.usernameInput}
						ref={usernameRef}
						id="usernameInput"
						type="text"
						placeholder="new username"
					/>
					<button className={style.usernameButtonInput}>Change Username</button>
				</form>

				<form className={style.colorSettingContainer} onSubmit={changeColor}>
					<label htmlFor="ColorPickerInput">USERNAME COLOR:</label>
					<h2 className={style.success}>{!getColor ? "" : getColor}</h2>
					<input
						className={style.ColorPickerInput}
						ref={userColorRef}
						id="ColorPickerInput"
						type="color"
					></input>
					<button className={style.colorButtonInput}>Set Color</button>
				</form>

				<form
					className={style.profilePictureSettingContainer}
					onSubmit={setProfilePic}
				>
					<label htmlFor="profileInput">PROFILE PICTURE:</label>
					<h2 className={style.error}>{!profileError ? "" : profileError}</h2>
					<h2 className={style.success}>{!getSuccess ? "" : getSuccess}</h2>
					<input
						type="text"
						id="profileInput"
						ref={profileURLRef}
						className={style.profileInput}
						placeholder="image url"
					></input>
					<button className={style.profileButtonInput}>
						Set Profile Picture
					</button>
				</form>

				<form className={style.statusSettingContainer} onSubmit={changeStatus}>
					<label htmlFor="statusInput">PROFILE STATUS:</label>
					<h2 className={style.success}>{!getStatus ? "" : getStatus}</h2>
					<input
						className={style.statusInput}
						ref={profileStatusRef}
						id="statusInput"
						type="text"
						placeholder="whats on your mind?"
					></input>
					<button className={style.statusButtonInput}>Set Status</button>
				</form>

				<button className={style.BackToRoom} onClick={() => navigate("/room")}>
					&#8592; Back to the room
				</button>
			</div>
		</div>
	);
}

export default Settings;
