import { config } from "../../config";

export async function clearQueuePlaylist(userID) {
    await fetch(`${config.API_URL}/spotify/queue/all/${userID}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(async (response) => {
        if (response > 299) {
            console.log("An unknown error occured clearing the deck...", response.status, response.statusText)

        }
        let a = await response.json()
        console.log("successfully cleared deck! Re-loading...", a)
        return
    })
    
    return
}