import useLocalStorage from "./useLocalStorage";
import { useState, useEffect, useContext } from "react";
import style from "../css/SpotifyPlayer.module.css";
import { GlobalSocket } from "../App";
import PlayerProgress from "./PlayerProgress";
import { config } from "../config";
console.log("Player component loaded...");

let token;
let volumeTimer;
let volumeTimeout;
let deviceId;

function SpotifyPlayer() {
	console.log("Player code rendered...");

	let socket = useContext(GlobalSocket);

	const [essentials, setEssentials] = useLocalStorage("essentials");
	const [player, setPlayer] = useState(undefined);
	const [isPaused, setPaused] = useState(false);
	const [current_track, setTrack] = useState();
	const [isLiked, setLike] = useState();
	const [syncedTrack, setSyncedTrack] = useState();
	const [syncAnim, setSyncAnim] = useState(false);
	const [volumeSliderVisibility, setVolumeSliderVisibility] = useState(false);
	const [isMuted, setMute] = useState(false);

	async function refreshUserToken() {
		try {
			return await fetch(
				`${config.API_URL}/auth/refresh/${essentials.userID}`,
				{
					method: "GET",
				}
			)
				.then(async (response) => await response.json())
				.then(async (response) => {
					console.log("New Access Token", response.data.spotifyAccessToken);
					setEssentials({
						...essentials,
						accessToken: response.data.spotifyAccessToken,
					});
					return response.data.spotifyAccessToken;
				});
		} catch (error) {
			return { error: error };
		}
	}

	//set playback device & transfer playback
	async function setDevice(id) {
		return await fetch("https://api.spotify.com/v1/me/player", {
			body: JSON.stringify({
				device_ids: [id],
			}),
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			},
			method: "PUT",
		}).then((response) => {
			console.log("Transfering playback...");
			console.log(response.status, response.statusText);
			return response.json;
		});
	}

	// create player and start listening sockets
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://sdk.scdn.co/spotify-player.js";
		script.async = true;
		document.body.appendChild(script);
		window.onSpotifyWebPlaybackSDKReady = () => {
			const player = new window.Spotify.Player({
				name: "Private DJ Club",
				getOAuthToken: async (cb) => {
					token = await refreshUserToken();
					cb(token);
				},
				volume: 0.5,
			});

			setPlayer(player);

			player.addListener("ready", async ({ device_id }) => {
				await setDevice(device_id);
				console.log("Ready with Device ID", device_id);
				deviceId = device_id;
			});

			player.addListener("not_ready", ({ device_id }) => {
				console.log("Device ID has gone offline", device_id);
			});

			player.addListener("player_state_changed", (state) => {
				if (!state) {
					return;
				}
				setTrack(state.track_window.current_track);
			});
			console.log("Player code running....");
			player.connect();
			console.log("Player finished loading...");
		};

		socket.on("playTrack", async (trackReq) => {
			if (isPaused === true) {
				console.log("New track ignored, player is paused.");
			}
			setLike();
			console.log("#playTrack", trackReq);
			await fetch(`${config.API_URL}/spotify/play`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					userID: essentials.userID,
					uri: trackReq.track.uri,
					pos: trackReq.sync.pos,
					deviceID: deviceId,
				}),
			}).then((response) => {
				let jsonResponse = response.json();
				console.log(jsonResponse);
				return jsonResponse;
			});
			console.log("Starting Track:", trackReq);
			setSyncedTrack(trackReq);
		});

		return () => {
			socket.off("playTrack");
		};
	}, []);

	async function handleLikeClick(trackID) {
		await fetch(
			`${config.API_URL}/spotify/like/${essentials.userID}/${trackID}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
			}
		).then(async (response) => {
			let jsonResponse = await response.json();
			if (jsonResponse.status === 200) {
				setLike(!isLiked);
				console.log(jsonResponse);
				return jsonResponse;
			} else {
				console.log(
					"An unknown error occured while attempting to like the track, notify a developer."
				);
				return;
			}
		});
	}

	async function handleUnikeClick(trackID) {
		await fetch(
			`${config.API_URL}/spotify/unlike/${essentials.userID}/${trackID}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
			}
		).then(async (response) => {
			let jsonResponse = await response.json();
			if (jsonResponse.status === 200) {
				setLike(!isLiked);
				console.log(jsonResponse);
				return jsonResponse;
			} else {
				console.log(
					"An unknown error occured while attempting to like the track, notify a developer."
				);
				return;
			}
		});
	}

	async function handleVolumeChange(amount) {
		await fetch(
			`${config.API_URL}/spotify/volume/${essentials.userID}/${amount}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
			}
		).then(async (response) => {
			let jsonResponse = await response.json();
			if (jsonResponse.status === 200) {
				console.log(jsonResponse);
				return jsonResponse;
			} else {
				console.log(
					"An unknown error occured while attempting to like the track, notify a developer."
				);
				return;
			}
		});
	}

	function syncTrack() {
		socket.emit("syncTrack", essentials);
	}

	function volumeVisible() {
		if (volumeSliderVisibility === true) {
			return;
		}
		console.log("enter");
		volumeTimer = setTimeout(() => {
			setVolumeSliderVisibility(true);

			console.log("ran");
			clearTimeout(volumeTimer);
			volumeTimeout = setTimeout(() => {
				setVolumeSliderVisibility(false);
			}, 5000);
		}, 500);
	}

	function volumeLeave() {
		clearTimeout(volumeTimer);
		console.log("leave");
		volumeTimer = setTimeout(() => {
			setVolumeSliderVisibility(false);
		}, 4000);
	}

	function resetVolumeTimer() {
		console.log("timer reset");
		clearTimeout(volumeTimer);
		clearTimeout(volumeTimeout);
	}

	function setVolume(volume) {
		console.log(volume);
		handleVolumeChange(volume);
		setEssentials({ ...essentials, volume: volume });
	}

	function debounce(func, timeout = 500) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	async function pausePlayButton() {
		if (isPaused === true) {
			console.log("Resuming player...");
			setPaused(!isPaused);
			syncTrack();
		} else {
			console.log("Pausing player");
			setPaused(!isPaused);
			await fetch(
				`${config.API_URL}/spotify/pause/${essentials.userID}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
				}
			).then(async (response) => {
				let jsonResponse = await response.json();
				if (jsonResponse.status === 200) {
					console.log(jsonResponse);
					return jsonResponse;
				} else {
					console.log(
						"An unknown error occured while attempting to pause the player, notify a developer.",
						jsonResponse
					);
					return;
				}
			});
		}
	}

	return (
		<div className={style.playerContainer}>
			<div onClick={pausePlayButton} className={style.albumCover}>
				{isPaused ? (
					<svg className={style.pauseButton} viewBox="0 0 512 512">
						<path d="M133 440a35.37 35.37 0 01-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0135.77.45l247.85 148.36a36 36 0 010 61l-247.89 148.4A35.5 35.5 0 01133 440z" />
					</svg>
				) : (
					<svg className={style.pauseButton} viewBox="0 0 512 512">
						<path d="M208 432h-48a16 16 0 01-16-16V96a16 16 0 0116-16h48a16 16 0 0116 16v320a16 16 0 01-16 16zM352 432h-48a16 16 0 01-16-16V96a16 16 0 0116-16h48a16 16 0 0116 16v320a16 16 0 01-16 16z" />
					</svg>
				)}
				<img
					alt="current-track-art"
					src={
						current_track
							? current_track.album.images[0].url
							: "https://media.giphy.com/media/l3vQY93bN54rXJTrO/giphy.gif"
					}
				/>
			</div>
			<PlayerProgress syncTrack={syncedTrack} className={style.progressBar}/>
			<div className={style.infoButtons}>
				{isLiked ? (
					<svg
						className={style.liked}
						onClick={() => {
							handleUnikeClick(current_track.id);
						}}
						viewBox="0 0 512 512"
					>
						<path d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
					</svg>
				) : (
					<svg
						className={style.unLiked}
						onClick={() => {
							handleLikeClick(current_track.id);
						}}
						viewBox="0 0 512 512"
					>
						{" "}
						<path
							d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="32"
						/>
					</svg>
				)}

				<svg
					className={syncAnim ? style.syncAnimButton : style.syncButton}
					onClick={() => {
						setSyncAnim(true);
						syncTrack();
						setTimeout(() => {
							setSyncAnim(false);
						}, 3000);
					}}
					viewBox="0 0 512 512"
				>
					<path
						d="M434.67 285.59v-29.8c0-98.73-80.24-178.79-179.2-178.79a179 179 0 00-140.14 67.36m-38.53 82v29.8C76.8 355 157 435 256 435a180.45 180.45 0 00140-66.92"
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="32"
					/>
					<path
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="32"
						d="M32 256l44-44 46 44M480 256l-44 44-46-44"
					/>
				</svg>

				{isMuted ? (
					<svg
						className={style.volumeButtonMuted}
						onClick={() => {
							setVolumeSliderVisibility(true);
							setMute(!isMuted);
							handleVolumeChange(essentials.volume);
							volumeLeave();
						}}
						viewBox="0 0 512 512"
					>
						<title>Volume Mute</title>
						<path
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeMiterlimit="10"
							strokeWidth="32"
							d="M416 432L64 80"
						/>
						<path d="M224 136.92v33.8a4 4 0 001.17 2.82l24 24a4 4 0 006.83-2.82v-74.15a24.53 24.53 0 00-12.67-21.72 23.91 23.91 0 00-25.55 1.83 8.27 8.27 0 00-.66.51l-31.94 26.15a4 4 0 00-.29 5.92l17.05 17.06a4 4 0 005.37.26zM224 375.08l-78.07-63.92a32 32 0 00-20.28-7.16H64v-96h50.72a4 4 0 002.82-6.83l-24-24a4 4 0 00-2.82-1.17H56a24 24 0 00-24 24v112a24 24 0 0024 24h69.76l91.36 74.8a8.27 8.27 0 00.66.51 23.93 23.93 0 0025.85 1.69A24.49 24.49 0 00256 391.45v-50.17a4 4 0 00-1.17-2.82l-24-24a4 4 0 00-6.83 2.82zM125.82 336zM352 256c0-24.56-5.81-47.88-17.75-71.27a16 16 0 00-28.5 14.54C315.34 218.06 320 236.62 320 256q0 4-.31 8.13a8 8 0 002.32 6.25l19.66 19.67a4 4 0 006.75-2A146.89 146.89 0 00352 256zM416 256c0-51.19-13.08-83.89-34.18-120.06a16 16 0 00-27.64 16.12C373.07 184.44 384 211.83 384 256c0 23.83-3.29 42.88-9.37 60.65a8 8 0 001.9 8.26l16.77 16.76a4 4 0 006.52-1.27C410.09 315.88 416 289.91 416 256z" />
						<path d="M480 256c0-74.26-20.19-121.11-50.51-168.61a16 16 0 10-27 17.22C429.82 147.38 448 189.5 448 256c0 47.45-8.9 82.12-23.59 113a4 4 0 00.77 4.55L443 391.39a4 4 0 006.4-1C470.88 348.22 480 307 480 256z" />
					</svg>
				) : (
					<svg
						className={style.volumeButton}
						onClick={() => {
							setVolumeSliderVisibility(false);
							setMute(!isMuted);
							handleVolumeChange(0);
						}}
						onMouseEnter={volumeVisible}
						onMouseLeave={() => {
							clearInterval(volumeTimer);
						}}
						viewBox="0 0 512 512"
					>
						{" "}
						<path
							d="M157.65 192H88a8 8 0 00-8 8v112a8 8 0 008 8h69.65a16 16 0 0110.14 3.63l91.47 75a8 8 0 0012.74-6.46V119.83a8 8 0 00-12.74-6.44l-91.47 75a16 16 0 01-10.14 3.61zM352 320c9.74-19.41 16-40.81 16-64 0-23.51-6-44.4-16-64M400 368c19.48-34 32-64 32-112s-12-77.7-32-112"
							fill="none"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="32"
						/>
					</svg>
				)}
			</div>

			<div className={style.trackInfo}>
				<div className={style.trackTitle}>
					{current_track ? current_track.name : "Loading..."}
				</div>
				<div className={style.trackArtist}>
					{current_track ? current_track.artists[0].name : "Loading..."}
				</div>
			</div>

			{volumeSliderVisibility ? (
				<input
					onMouseLeave={volumeLeave}
					onMouseEnter={resetVolumeTimer}
					className={style.volumeSlider}
					type="range"
					min={0}
					max={100}
					defaultValue={essentials.volume}
					step={5}
					onChange={debounce((a) => {
						setVolume(a.target.value);
					})}
				/>
			) : (
				""
			)}
		</div>
	);
}

export default SpotifyPlayer;
