import { config } from "../../config";
import { refreshUserToken } from "./refreshUserToken";

export async function createPlaylists(userObj) {
	let createQueuePlaylist = async (token) => {
		try {
			return await fetch(
				"https://api.spotify.com/v1/users/" + userObj.spotifyID + "/playlists",
				{
					body: JSON.stringify({
						name: "DJ Club Queue",
						description: "Tracks here will be loaded into your queue.",
						public: false,
					}),
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
					method: "POST",
				}
			).then(async (response) => {
				if (response.status > 299) {
					console.log(
						"#createSavedPlaylist An error occured:",
						response.status,
						response
					);
					return await refreshUserToken(userObj._id, createPlaylists);
				}
				let a = await response.json();
				return a;
			});
		} catch (error) {
			return {
				message: "CreateQueuePlaylist:Loading unknown error occured",
				error: error,
			};
		}
	};

	let createSavedPlaylist = async (token) => {
		try {
			return await fetch(
				"https://api.spotify.com/v1/users/" + userObj.spotifyID + "/playlists",
				{
					body: JSON.stringify({
						name: "DJ-Club Saved",
						description: "Saved tracks will be found here",
						public: false,
					}),
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
					method: "POST",
				}
			).then(async (response) => {
				if (response.status > 299) {
					console.log(
						"#createSavedPlaylist An error occured:",
						response.status,
						response
					);
					return await refreshUserToken(userObj._id, createPlaylists);
				}
				let a = await response.json();
				return a;
			});
		} catch (error) {
			return {
				message: "CreateSavedPlaylist:Loading unknown error occured",
				error: error,
			};
		}
	};

	let queue = await createQueuePlaylist(userObj.spotifyAccessToken);
	let saved = await createSavedPlaylist(userObj.spotifyAccessToken);
	console.log("#createPlaylists", queue, saved);

	return await fetch(`${config.API_URL}/users/${userObj._id}`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			lists: { queue: queue, saved: saved },
		}),
	})
		.then((response) => {
			console.log(response.status);
			response.json();
		})
		.then(async (response) => {
			console.log("#patchUserProfile:", response);
			return response;
		});
}
