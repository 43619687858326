import UserQueue from "../components/UserQueue";
import SongMeter from "../components/SongMeter";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalSocket } from "../App";
import useLocalStorage from "../components/useLocalStorage";

function HomePage() {
	let socket = useContext(GlobalSocket);
	const navigate = useNavigate();
	const [essentials] = useLocalStorage("essentials");

	useEffect(() => {
		// Check if user is illegally logging in, if not continue
		if (essentials === undefined) {
			console.log("User is NOT logged in, redirecting back to login page");
			navigate("/login", { replace: true });
			return;
		} else if (essentials.userID === undefined) {
			console.log("User is NOT logged in, redirecting back to login page");
			navigate("/login", { replace: true });
			return;
		}

		//Update array for everyone if user socket id is not already in the array list.
		socket.emit("online", essentials);
	});

	return (
		<div>
			<UserQueue />
		</div>
	);
}

export default HomePage;
