import { useRef, useState } from "react";
import style from "../css/Queue.module.css";
import SearchResults from "./SearchResults";
import Loader from "./Loader";
import Deck from "./Deck";
import useLocalStorage from "./useLocalStorage";
import { clearQueuePlaylist } from "../api/spotify/clearQueuePlaylist";

function Queue() {
	let inputRef = useRef("");
	let artistCheck = useRef();
	let trackCheck = useRef();
	let playlistCheck = useRef();

	const [essentials] = useLocalStorage("essentials");
	const [searchQuery, setSearch] = useState();
	const [searchError, setSearchError] = useState("");
	const [isChecked, setCheck] = useState("track");
	const [isLoading, setLoading] = useState();
	const [lastAdded, deckAdd] = useState("");
	const [deckCount, setDeckCount] = useState(0);

	function handleSubmit(event) {
		event.preventDefault();
		setSearchError("");
		console.log("Input:", inputRef.current.value);

		if (inputRef.current.value === "") {
			console.log("Blank detected");
			setSearchError("Please enter a search request");
			return;
		}

		if (isChecked !== undefined) {
			console.log("test");
			setSearch(inputRef.current.value);
			inputRef.current.value = "";
			return;
		} else {
			console.log("No search type selected");
			setSearchError("↓ You must select a search type");
			return;
		}
	}

	function handleCheckmark(button) {
		// console.log(button.current.id)
		setSearchError("");

		if (
			trackCheck.current.checked === false &&
			artistCheck.current.checked === false &&
			playlistCheck.current.checked === false
		) {
			setCheck(undefined);
			return;
		}

		if (button.current.id === "artistCheckbox") {
			trackCheck.current.checked = false;
			playlistCheck.current.checked = false;
			setCheck("artist");
		} else if (button.current.id === "playlistCheckbox") {
			artistCheck.current.checked = false;
			trackCheck.current.checked = false;
			setCheck("playlist");
		} else if (button.current.id === "trackCheckbox") {
			artistCheck.current.checked = false;
			playlistCheck.current.checked = false;
			setCheck("track");
		}
	}

	function handleResultClick(track) {
		console.log("New track added to deck! Re-loading...");
		console.log("#handleResultClick adding:", track);
		deckAdd(track);
	}

	async function handleClearClick() {
		// API CALL to backend. Clear spotify playlist.
		await clearQueuePlaylist(essentials.userID);
		deckAdd("clear");
	}

	function recieveDeckLength(length) {
		setDeckCount(length);
	}

	return (
		<div className={style.queueContainer}>
			<div className={style.deckContainer}>
				<h1>{"DECK (" + deckCount + ")"}</h1>
				<h2>Add tracks into your deck</h2>
				<div className={style.deckButtons}>
					<button>SHUFFLE</button>
					<button
						onClick={() => {
							handleClearClick();
						}}
					>
						CLEAR
					</button>
				</div>
				<div className={style.dragArea}>
					<Deck deckUpdate={lastAdded} deckCount={recieveDeckLength} />
				</div>
			</div>

			<div className={style.crateContainer}>
				<h1>CRATE</h1>
				<form className={style.form}>
					<input
						type="text"
						className={style.inputText}
						ref={inputRef}
						onSubmit={handleSubmit}
						placeholder="Start searching for music..."
					></input>
					<h2 className={style.error}>{!searchError ? "" : searchError}</h2>
					<div className={style.searchOptions}>
						<div className={style.checkmarks}>
							<label htmlFor="artistCheckbox">Artist:</label>
							<input
								type="checkbox"
								id="artistCheckbox"
								ref={artistCheck}
								onClick={() => handleCheckmark(artistCheck)}
							></input>
							<label htmlFor="trackCheckbox">Track:</label>
							<input
								type="checkbox"
								id="trackCheckbox"
								ref={trackCheck}
								defaultChecked={isChecked === "track" ? true : false}
								onClick={() => handleCheckmark(trackCheck)}
							></input>
							<label htmlFor="playlistCheckbox">Playlists:</label>
							<input
								type="checkbox"
								id="playlistCheckbox"
								ref={playlistCheck}
								onClick={() => handleCheckmark(playlistCheck)}
							></input>
						</div>
						<button className={style.inputButton} onClick={handleSubmit}>
							SEARCH
						</button>
					</div>
				</form>

				<div className={style.searchResults}>
					{isLoading ? <Loader /> : ""}
					<SearchResults
						search={{ searchQuery, isChecked }}
						trackAdded={handleResultClick}
						loading={setLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default Queue;
