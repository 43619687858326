import style from "../css/Loading.module.css";
import useLocalStorage from "../components/useLocalStorage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createPlaylists } from "../api/spotify/createPlaylists";
import { getUserProfile } from "../api/user/getUserProfile";
import { loginUser } from "../api/auth/loginUser";

function HomePage() {
	const navigate = useNavigate();
	const [essentials, setEssentials] = useLocalStorage("essentials");

	async function finalize(userId) {

		console.log(`This is a userID:`, userId)

		let a = await getUserProfile(userId);
		//Check if the user already has playlists registered in the database, if not create.
		if (!a.queuePlaylist && !a.savedPlaylist) {
			console.log("#initLogin playlists do not exist, creating...");
			let lists = await createPlaylists(a);
			console.log("#initLogin lists created:", lists);
		}

		//Set and create essentials in users localstorage
		await essCreate(a);

		//Send user to room
		navigate("/room", { replace: true });
		return;
	}

	//create essentials object and add it to the user's local
	// - Check if this conflicts with essentials added later
	// - Remove access token from essentials object
	async function essCreate(response) {
		let obj = {
			username: response.username,
			userID: response._id,
			colorCode: response.colorCode,
			profilePic: response.profilePic,
			accessToken: response.spotifyAccessToken,
		};
		await setEssentials(obj);
		return;
	}

	useEffect(() => {
		// Check if user is illegally logging in, if not continue
		if (essentials !== undefined) {
			if (essentials.userID !== undefined) {
				console.log("User is already logged in, fetching...");
				finalize(essentials.userID);
				return;
			}
		}

		//Get auth token in url from spotify redirect
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});

		//If no spotify auth token is sent back, error
		if (params.code == null || params.error) {
			console.log("User has not been authorized");
			navigate("/login", { replace: true });
			return;
		}

		// Initial set of userID if NOT logged in
		async function initAuth(code) {
			//Get or create user ID from backend...
			let a = await loginUser(code);
			if (a === "error") {
				console.log("An error occured with loginUser. Check logs...");
				navigate("/login", { replace: true });
				return;
			}
			//Complete Login procedure
			await finalize(a);
		}

		initAuth(params.code);
	});

	return (
		<div className={style.loadingContainer}>
			<h1>Loading...</h1>
			<img
				src="https://media.tenor.com/y2JXkY1pXkwAAAAM/cat-computer.gif"
				alt="loading gif"
			></img>
		</div>
	);
}

export default HomePage;
