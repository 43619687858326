import { useEffect, useState } from "react"
import style from "../css/PlayerProgress.module.css"

let posInt

function PlayerProgress(props) {

const [progress, setProgress] = useState(0)

function startTimer() {
    if (props.syncTrack !== undefined) {
        console.log("Starting new progress bar timer")
        let syncDifference

        //Caluclate Server/Client time difference on Sync/Initial track load
        if (props.syncTrack.sync.pos) {
            syncDifference = (Date.now() - props.syncTrack.sync.timeStarted) - props.syncTrack.sync.pos
            console.log("Sync Difference:", syncDifference)
        } else {
            syncDifference = Date.now() - props.syncTrack.sync.timeStarted
            console.log("Client Difference:", syncDifference)
        }

        //Start progress bar interval for every 1 second until track end time is reached
        posInt = setInterval(() => {
            if (Date.now() >= (props.syncTrack.track.duration_ms + (props.syncTrack.sync.timeStarted + syncDifference))) {
                console.log("Track ended, Resetting timer...")
                clearInterval(posInt)
                setProgress(0)
                return
            }
    
        //Progress left calulation thanks to RiverThrough for the quick maths
            let progressLeft = Date.now() - (props.syncTrack.sync.timeStarted + syncDifference)
            // console.log("testProgress:", new Date(Date.now() - (props.syncTrack.sync.timeStarted + syncDifference)).toISOString().slice(15, -5))
            // console.log(progressLeft, "Now", Date.now(), "started:", props.syncTrack.sync.timeStarted, "Dur:", props.syncTrack.track.duration_ms, "Ending:", props.syncTrack.track.duration_ms + props.syncTrack.sync.timeStarted)
            setProgress(progressLeft)
        }, (1000));
    }
    
}

useEffect(() => {
    if (props.syncTrack === undefined) {
        return
    }
    
    console.log("Resetting timer")
    clearInterval(posInt)
    setProgress(0)

    startTimer()

}, [props.syncTrack])

    return <div className={style.progressBar}>
            <div className={style.progress} style={{width: props.syncTrack ? ((progress / props.syncTrack.track.duration_ms) * 100).toFixed(2) + "%" : 0 + "%"}}></div>
            {props.syncTrack ? <div className={style.timeDisplay}>
                <span className={style.trackProgress}>{new Date(progress).toISOString().slice(15, -5)}</span>
                <span className={style.trackLength}>{new Date(props.syncTrack.track.duration_ms).toISOString().slice(15, -5)}</span>
            </div> : ""}
        </div>
}

export default PlayerProgress