import { Route, Routes } from "react-router-dom";
import RoomPage from "./pages/RoomPage";
import ProfilePage from "./pages/ProfilePage";
import QueuePage from "./pages/QueuePage";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import Loading from "./pages/Loading";
import io from "socket.io-client";
import React from "react";
import RoomLayout from "./components/RoomLayout";
import { config } from "./config";

const socket = io.connect('https://socialmusic.agp2p.com', {
	path: "/ws/"
  });

console.log("Connected?")

// const socket = io.connect(`https://socialmusic.agp2p.com/`);


export const GlobalSocket = React.createContext();

function App(props) {
	return (
		<div>
			<GlobalSocket.Provider value={socket}>
				<Routes>
					<Route path="/" element={<LoginPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/loading" element={<Loading />} />
					<Route path="/room" element={<RoomLayout />}>
						<Route index element={<RoomPage />} />
						<Route path="profile" element={<ProfilePage />} />
						<Route path="queue" element={<QueuePage />} />
						<Route path="settings" element={<SettingsPage />} />
					</Route>
				</Routes>
			</GlobalSocket.Provider>
		</div>
	);
}

export default App;
