import style from "../css/Messages.module.css"

function Messages(props) {
    const urlCheck = /\b(https?:\/\/\S+(?:png|jpe?g|gif)\S*)\b/igm

    let a = props.msgList.map((item, index) => {
        // console.log(item[0].text)
        let userColor = {
            color: item[0].colorCode
        }
        if (item[0].text.match(urlCheck)) {
            let url = item[0].text.match(urlCheck)
            let cleanText = item[0].text.replace(url, "")
            return <li key={index} className={style.attachMsg}>
                <div className={style.textContainer}>
                    <div className={style.identifier}>
                        <div className={style.username} style={userColor}>{item[0].username}</div>
                        <div className={style.chatSeperator}>:</div>
                    </div>
                    <div className={style.text}>{cleanText}</div>
                </div>
                <img className={style.image} src={url} alt={cleanText}></img>
            </li>
        }
        return <li key={index} className={style.attachMsg}>
        <div className={style.textContainer}>
            <div className={style.identifier}>
                <div className={style.username} style={userColor}>{item[0].username}</div>
                <div className={style.chatSeperator}>:</div>
            </div>
            <div className={style.text}>{item[0].text}</div>
        </div>
    </li>
    })

    return(
        a
    )
}

export default Messages
