import { useState, useEffect, useRef, useContext } from "react"
import { GlobalSocket } from "../App"
import style from "../css/Chat.module.css"
import Messages from "./Messages"
console.log("Chat component loaded...")

function Chat() {
    let inputRef = useRef("")
    // let chatAnchor = useRef()
    const socket = useContext(GlobalSocket)
    const [ list, setNewList] = useState([])

    function handleSubmit(event) {
        event.preventDefault()
        console.log("Input:", inputRef.current.value)
        if (inputRef.current.value === "") {
            console.log("Blank detected, not sending...")
            return
        }
        let localInfo = JSON.parse(localStorage.getItem("essentials"))
        console.log(localInfo)
        //Send chat message to server for clients and clear value
        socket.emit("chat-message", { username: localInfo.username, colorCode: localInfo.colorCode, text: inputRef.current.value})
        console.log("Chat-message EMIT")
        inputRef.current.value = ""
    }

    useEffect(() => {
        //Receieve chat messages from other clients
        socket.on("new-message", (message) => {
            console.log("new-message RECEIVE")
            console.log(message)
            setNewList(arr => [...arr, [message]])
        })

        return () => {
            socket.off("new-message")
        }

    },[socket])

    return (
        <div className={style.chatContainer}>
            <ul className={style.chatMessages}>
                <Messages msgList={list}/>
                <div className={style.chatAnchor}></div>
            </ul>
            <form className={style.chatForm} onSubmit={handleSubmit}>
                <button className={style.chatGifButton}>GIF</button>
                <button className={style.chatEmojiButton}>😂</button>
                <input className={style.chatInput} ref={inputRef}/><button className={style.chatButtonSend}>SEND</button>
            </form> 
        </div>

    )
}

export default Chat