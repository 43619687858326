import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Login from "../components/Login"
import Backdrop from "../components/Backdrop"
import useLocalStorage from "../components/useLocalStorage";
import style from "../css/Login.module.css"


function LoginPage(props) {

    const navigate = useNavigate()
    const [essentials] = useLocalStorage("essentials")

    useEffect(() => {

        if (essentials !== undefined) {
            if (essentials.userID !== undefined) {
                console.log("User is already logged in, redirecting")
                navigate('/loading', { replace: true })
            }
        }
    }, [])

    return (
        <div className={style.background}>
            {<Login title={"WELCOME TO THE PRIVATE DJ CLUB"} />}
            {<Backdrop/>}
        </div>
    )
}

export default LoginPage