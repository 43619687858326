import { useEffect, useState } from "react"
import { config } from "../config";
import style from "../css/SearchResults.module.css"
import useLocalStorage from "./useLocalStorage"
import PreviewButton from "./PreviewButton"
let searchHistory = []
let buttonReset

function SearchResults(props) {
    
    const [ resultBox, setResultBox ] = useState({results: [], controller: ""})
    const [ resultArray, setResults ] = useState([])
    const [ controller, setController ] = useState("")
    const [ essentials, setEssentials ] = useLocalStorage("essentials")

    function setHistory(obj) {
        setController(obj.controller)
        setResults(obj.results)
    }

    function saveHistory(number, data) {
        if (!searchHistory[number]) {
            searchHistory.push(data)
            console.log("History", number, "created")
            return

        } else {
            if (data.results[0].key === searchHistory[number].results[0].key){
                console.log("duplicate")
                return
            }

            searchHistory.splice(number, 1, data)
            console.log("History", number, "replaced")
            return
        }
    }


    async function refreshSpotifyClientToken(callback) {
        try {
            return await fetch(`${config.API_URL}/auth/client/refresh`,{
                    method: "GET"
            })
            .then(response => response.json())
            .then((response) => {
                localStorage.setItem("clientToken", response.access_token)
                console.log("New token generated:", response.access_token)
                return callback()
            })

        } catch (error) {
            return {error: error}
        }
    }

    async function searchSpotify(type, query) {
        return await fetch(`${config.API_URL}/spotify/search/${type}/${essentials.userID}/${query}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            let b = await response.json()
            console.log(b)
            return b
        })
    }



//GET ALBUM TRACKS
    async function getAlbum(id) {
        props.loading(true)
        console.log("getting album")
        let call = async () => {
            return await fetch("https://api.spotify.com/v1/albums/" + id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("clientToken"),
                "Content-Type": "application/json"
            }
            })
            .then(async (response) => {
                if (response.status > 299) {
                    console.log("An error occured:", response.status, response)
                    return await refreshSpotifyClientToken(call)
                }
                return response.json()
            })
        }

        let albumTracks = await call()

        console.log(albumTracks)
        
        let resultHistory = albumTracks.tracks.items.map(i => {
            return (<li key={i.uri} className={style.lineItem}>
                <div className={style.detailContainer}>
                    <button className={style.addButton} onClick={() => {
                        console.log("#getAlbum adding:", i)
                        addTrack({
                            uri: i.uri,
                            artists: i.artists[0].name,
                            name: i.name,
                            image: albumTracks.images[2].url
                        })
                        }}>+</button>
                    <img className={style.albumArt} src={albumTracks.images[2].url} alt="track-art"/>
                    <div>
                        <div>{i.name}</div>
                        <div className={style.artistName}>{i.artists[0].name}</div>
                    </div>
                </div>
                <PreviewButton uri={i.uri} reset={previewButtonReset}/>
            </li>)
        })

        let resultController = (<li className={style.controller}>
            <button onClick={() => {
                setHistory(searchHistory[1])
            }}>BACK</button>
            <button onClick={() => {
                let entireAlbum = albumTracks.tracks.items.map(i => {
                    return {
                        uri: i.uri,
                        artists: i.artists[0].name,
                        name: i.name,
                        image: albumTracks.images.length > 0 ? albumTracks.images[2].url : "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
                    }
                })
                console.log("Adding entire Album:", entireAlbum)
                addTrack(entireAlbum)
            }}>ADD-ALL</button>
        </li>
        )

        setController(resultController)
        setResults(resultHistory)
        props.loading(false)
        saveHistory(2, {controller: resultController, results: resultHistory})
        console.log("History", searchHistory)

    }




//GET ARTIST ALBUMS
    async function goToArtist(id) {
        console.log("Fetching Artist albums ...")
        props.loading(true)
        let call = async () => {
            return await fetch("https://api.spotify.com/v1/artists/" + id  + "/albums", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("clientToken"),
                    "Content-Type": "application/json"
                }
            })
            .then(async (response) => {
                if (response.status > 299) {
                    console.log("An error occured:", response.status, response)
                    return await refreshSpotifyClientToken(call)
                }
                return response.json()
            })
        }
        
        let artists = await call()

        let resultHistory = artists.items.map(i => {
            return (<li key={i.id} className={style.lineItem}>
                <div className={style.detailContainer}>
                    <img className={style.albumArt} alt="track-art" src={i.images.length > 0 ? i.images[2].url : "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"}/>
                    <div>
                        <div>{i.name}</div>
                        <div className={style.artistName}>{i.release_date}</div>
                    </div>
                </div>
                <button className={style.goButton} onClick={() => {
                    getAlbum(i.id)
                }}>GO</button>
            </li>)
        })

        let resultController = (<li className={style.controller}>
            <div>ARTIST ALBUMS</div>
            <button onClick={() => {
                setHistory(searchHistory[0])
            }}>BACK</button>
        </li>)

        setController(resultController)
        setResults(resultHistory)
        props.loading(false)
        saveHistory(1, {controller: resultController, results: resultHistory})
        console.log("History", searchHistory)

    }


//GET PLAYLIST TRACKS
    async function getPlaylist(id) {
        console.log("Fetching Artist albums ...")
        props.loading(true)
        let playlist = await fetch(`${config.API_URL}/spotify/search/getplaylist/${essentials.userID}/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            return await response.json()
        })

        console.log(playlist)

        let resultHistory = playlist.tracks.items.map(i => {
            return (<li key={i.track.id} className={style.lineItem}>
                <div className={style.detailContainer}>
                <button className={style.addButton} onClick={() => {
                    
                    console.log("Playlist", i)
                    
                    addTrack({
                        uri: i.track.uri,
                        artists: i.track.artists[0].name,
                        name: i.track.name,
                        image: i.track.album.images.length > 0 ? i.track.album.images[2].url : "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
                    })

                    }}>+</button>
                    <img className={style.albumArt} alt="track-art" src={i.track.album.images.length > 0 ? i.track.album.images[2].url : "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"}/>
                    <div>
                        <div>{i.track.name}</div>
                        <div className={style.artistName}>{i.track.artists[0].name}</div>
                    </div>
                </div>
                <PreviewButton uri={i.track.uri} reset={previewButtonReset}/>
            </li>)
        })

        let resultController = (<li className={style.controller}>
            <div>PLAYLIST TRACKS</div>
            <button onClick={() => {
                setHistory(searchHistory[0])
            }}>BACK</button>
            <button onClick={() => {
                let entirePlaylist = playlist.tracks.items.map(a => {
                    return {
                        uri: a.track.uri,
                        artists: a.track.artists[0].name,
                        name: a.track.name,
                        image: a.track.album.images.length > 0 ? a.track.album.images[2].url : "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
                    }
                })
                console.log("Adding entire playlist:", entirePlaylist)
                addTrack(entirePlaylist)
            }}>ADD-ALL</button>
        </li>)

        setController(resultController)
        setResults(resultHistory)
        props.loading(false)
        saveHistory(1, {controller: resultController, results: resultHistory})
        console.log("History", searchHistory)

    }

    function previewButtonReset(resetFunc) {
		if (buttonReset) {
			console.log("resetting previous button")
			buttonReset()
			buttonReset = resetFunc
			return
		}

		console.log("setting initial button")
		buttonReset = resetFunc
		console.log(resetFunc)
	}



    async function addTrack(obj) {
        console.log("#addtrack:", obj)
        try {
            await fetch(`${config.API_URL}/spotify/queue/${essentials.userID}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    position: 0,
                    uri: obj.length > 0 ? obj.map(a => a.uri) : obj.uri
                })
            })
            .then(async (response) => {
                return await response.json()
            })
            props.trackAdded(obj)
        } catch (error) {
            console.log("#addtrack:", error)
        }
    }

    async function getMyLikedTracks() {
        props.loading(true)
        let a = await fetch(`${config.API_URL}/spotify/search/liked/${essentials.userID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            return await response.json()
        })

        let resultHistory = a.items.map(i => {
            return (<li key={i.track.uri} className={style.lineItem}>
                        <div className={style.detailContainer}>
                            <button className={style.addButton} onClick={() => addTrack(
                                {
                                    uri: i.track.uri,
                                    artists: i.track.artists[0].name,
                                    name: i.track.name,
                                    image: i.track.album.images[2].url
                                }
                            )}>+</button>
                            <img className={style.albumArt} alt="track-art" src={i.track.album.images[2].url}/>
                            <div>
                                <div>{i.track.name}</div>
                                <div className={style.artistName}>{i.track.artists[0].name}</div>
                            </div>
                        </div>
                        <PreviewButton uri={i.track.uri} reset={previewButtonReset}/>
                    </li>)
        })
        setController("")
        setResults(resultHistory)
        props.loading(false)
    }

    async function getMyPlaylists() {
        props.loading(true)
        let a = await fetch(`${config.API_URL}/spotify/search/user/playlists/${essentials.userID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            return await response.json()
        })

        console.log(a)

        let resultHistory = a.items.map(i => {
            return (<li key={i.id} className={style.lineItem}>
                <div className={style.detailContainer}>
                    <img className={style.albumArt} alt="track-art" src={i.images.length > 0 ? i.images[0].url : "https://i.pinimg.com/originals/2e/c2/98/2ec2982225fd5272476b5886c86898cb.jpg"}/>
                    <div>
                        <div>{i.name}</div>
                        <div className={style.artistName}>{"By " + i.owner.display_name}</div>
                    </div>
                </div>
                    <button className={style.goButton} onClick={() => {getPlaylist(i.id)}}>GO</button>
            </li>)
        }) 
        let controller = ""
        setResults(resultHistory)
        setController(controller)
        saveHistory(0, {controller: controller, results: resultHistory})
        props.loading(false)
        console.log("History", searchHistory)
    }
    






//SET CONTROLLERS ON CHECKMARK
    useEffect(() => {
        setResults("")

        if (props.search.isChecked === "track") {
            console.log("render track controller")
            let trackController = (<li className={style.controller}>
                <div></div>
                <button onClick={async () => {
                    getMyLikedTracks()
                }}>My Liked Songs</button>
            </li>)
            setController(trackController)
            return

        } else if (props.search.isChecked === "playlist") {
            console.log("render playlist controller")
            let playlistController = (<li className={style.controller}>
                <div></div>
                <button onClick={async () => {
                    getMyPlaylists()
                }}>My Playlists</button>
            </li>)
            setController(playlistController)
            return
        } else if (props.search.isChecked === "artist") {
            setController("")
        }
        
    }, [props.search.isChecked])




//TRACK, ARITST, and PLAYLIST INTIAL SEARCH
    useEffect(()=> {
        console.log("Searching for:", props.search.searchQuery, props.search.isChecked)
        if (props.search.searchQuery !== undefined) {
            (async () => {
                props.loading(true)
                let a = await searchSpotify(props.search.isChecked, props.search.searchQuery)
                //Track Search Begin
                if (a.tracks) {
                    let resultHistory = a.tracks.items.map(i => {
                        return (<li key={i.uri} className={style.lineItem}>
                                    <div className={style.detailContainer}>
                                        <button className={style.addButton} onClick={() => addTrack(
                                            {
                                                uri: i.uri,
                                                artists: i.artists[0].name,
                                                name: i.name,
                                                image: i.album.images[2].url
                                            }
                                        )}>+</button>
                                        <img className={style.albumArt} alt="track-art" src={i.album.images[2].url}/>
                                        <div>
                                            <div>{i.name}</div>
                                            <div className={style.artistName}>{i.artists[0].name}</div>
                                        </div>
                                    </div>
                                    <PreviewButton uri={i.uri} reset={previewButtonReset}/>
                                </li>)
                    }) 
                    
                    let trackController = (<li className={style.controller}>
                        <div></div>
                        <button onClick={async () => {
                            getMyLikedTracks()
                        }}>My Liked Songs</button>
                    </li>)
                    setController(trackController)
                    setResults(resultHistory)
                    props.loading(false)
                    console.log("History", searchHistory)
                } 
                
                //Artist search Begin
                else if (a.artists){
                    setController("")
                    console.log(a.artists.items)
                    let resultHistory = a.artists.items.map(i => {
                        return (<li key={i.id} className={style.lineItem}>
                            <div className={style.detailContainer}>
                                <img className={style.albumArt} alt="track-art" src={i.images.length > 0 ? i.images[1].url : "https://i.pinimg.com/originals/2e/c2/98/2ec2982225fd5272476b5886c86898cb.jpg"}/>
                                <div>
                                    <div>{i.name}</div>
                                    <div className={style.artistName}>{i.genres.join(" ")}</div>
                                </div>
                            </div>
                                <button className={style.goButton} onClick={() => {goToArtist(i.id)}}>GO</button>
                        </li>)
                    })

                    setResults(resultHistory)
                    props.loading(false)
                    saveHistory(0, {controller: controller, results: resultHistory})
                    console.log("History", searchHistory)
                } 
                
                //Playlist search Begin
                else if (a.playlists){
                    console.log(a.playlists.items)
                    let resultHistory = a.playlists.items.map(i => {
                        return (<li key={i.id} className={style.lineItem}>
                            <div className={style.detailContainer}>
                                <img className={style.albumArt} alt="track-art" src={i.images.length > 0 ? i.images[0].url : "https://i.pinimg.com/originals/2e/c2/98/2ec2982225fd5272476b5886c86898cb.jpg"}/>
                                <div>
                                    <div>{i.name}</div>
                                    <div className={style.artistName}>{"By " + i.owner.display_name}</div>
                                </div>
                            </div>
                                <button className={style.goButton} onClick={() => {getPlaylist(i.id)}}>GO</button>
                        </li>)
                    })

                    let playlistController = (<li className={style.controller}>
                        <div></div>
                        <button onClick={async () => {
                            getMyPlaylists()
                        }}>My Playlists</button>
                    </li>)

                    setController(playlistController)
                    setResults(resultHistory)
                    props.loading(false)
                    saveHistory(0, {controller: playlistController, results: resultHistory})
                    console.log("History", searchHistory)
                }               
            })()
        }
    }, [props.search.searchQuery])

    console.log("SearchResults:", props.search)

    return <>
        <ul className={style.container}>
                {controller}
                {resultArray}
            <div className={style.spacer}></div>
        </ul>
    </>


}


export default SearchResults